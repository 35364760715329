import React from 'react';

const Star = ({fill = 'white', size = '22', stroke = '#EF9F84'}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    version='1.1'
    viewBox='0 0 22 22'
    width={size}
    height={size}
    fill={fill}
    stroke={stroke}
  >
    <path d='M11.4755 3.84549L11 2.38197L10.5245 3.84549L8.84062 9.02786H3.39155H1.85271L3.09766 9.93237L7.50604 13.1353L5.82219 18.3176L5.34666 19.7812L6.59161 18.8766L11 15.6738L15.4084 18.8766L16.6533 19.7812L16.1778 18.3176L14.494 13.1353L18.9023 9.93237L20.1473 9.02786H18.6085H13.1594L11.4755 3.84549Z' />
  </svg>
);

export default Star;
